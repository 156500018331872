import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Container`}</inlineCode>{` merupakan sebuah unsur yang digunakan untuk memuat unsur UI lain dalam ukuran optimal sesuai dengan ukuran layar yang digunakan user.`}</p>
    </PageDescription>
    <h2>{`Container System`}</h2>
    <h3>{`Background`}</h3>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1014px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "15.277777777777777%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAADABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3gUH/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFhAAAwAAAAAAAAAAAAAAAAAAABAR/9oACAEBAAE/ISL/2gAMAwEAAgADAAAAEAAP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBAAAgEFAQAAAAAAAAAAAAAAAAERITFBUWGR/9oACAEBAAE/EIXfRpGbbElU/9k=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "container",
        "title": "container",
        "src": "/static/a21c615d72b8e9208e2d2071c9ac15cc/671e4/background.jpg",
        "srcSet": ["/static/a21c615d72b8e9208e2d2071c9ac15cc/69549/background.jpg 288w", "/static/a21c615d72b8e9208e2d2071c9ac15cc/473e3/background.jpg 576w", "/static/a21c615d72b8e9208e2d2071c9ac15cc/671e4/background.jpg 1014w"],
        "sizes": "(max-width: 1014px) 100vw, 1014px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <p>{`Penggunaan Background pada antar muka:`}</p>
    <ul>
      <li parentName="ul">{`Gunakan background untuk penempatan pada level paling rendah`}</li>
      <li parentName="ul">{`Dapat menggunakan warna dari Design Tokens Color`}</li>
      <li parentName="ul">{`Sudut radius dapat disesuaikan`}</li>
      <li parentName="ul">{`Dapat digunakan sebagai objek yang di masking`}</li>
    </ul>
    <h3>{`Panel`}</h3>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1014px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "27.083333333333332%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAFABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3QUH/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFhAAAwAAAAAAAAAAAAAAAAAAABAR/9oACAEBAAE/ISL/2gAMAwEAAgADAAAAEPgP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGBAAAwEBAAAAAAAAAAAAAAAAABFRAUH/2gAIAQEAAT8QW2jXiM6f/9k=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "container",
        "title": "container",
        "src": "/static/4d517affee29c1bda6558c24caa1619d/671e4/panel.jpg",
        "srcSet": ["/static/4d517affee29c1bda6558c24caa1619d/69549/panel.jpg 288w", "/static/4d517affee29c1bda6558c24caa1619d/473e3/panel.jpg 576w", "/static/4d517affee29c1bda6558c24caa1619d/671e4/panel.jpg 1014w"],
        "sizes": "(max-width: 1014px) 100vw, 1014px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <p>{`Penggunaan Panel pada antar muka:`}</p>
    <ul>
      <li parentName="ul">{`Gunakan panel sebagai pemisah antara elemen`}</li>
      <li parentName="ul">{`Dapat menggunakan warna dari Design Tokens Color`}</li>
      <li parentName="ul">{`Sudut radius dapat disesuaikan`}</li>
      <li parentName="ul">{`Dapat digunakan sebagai objek yang di masking`}</li>
    </ul>
    <h3>{`Container`}</h3>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1014px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "115.97222222222221%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAXABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe8goMwUH//EABYQAQEBAAAAAAAAAAAAAAAAAAAhIP/aAAgBAQABBQLVVX//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAXEAADAQAAAAAAAAAAAAAAAAAAEDEg/9oACAEBAAY/AtQi/8QAFxABAAMAAAAAAAAAAAAAAAAAAQAwUf/aAAgBAQABPyGgAxP/2gAMAwEAAgADAAAAEDMIPP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB0QAQABBAMBAAAAAAAAAAAAAAEAESExQVFxgbH/2gAIAQEAAT8Q49jjwm3uV+MWz0QbvcTV4pkiX362S5WiryT/2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "container",
        "title": "container",
        "src": "/static/8e8946ffd9262beaac387aa045260805/671e4/container.jpg",
        "srcSet": ["/static/8e8946ffd9262beaac387aa045260805/69549/container.jpg 288w", "/static/8e8946ffd9262beaac387aa045260805/473e3/container.jpg 576w", "/static/8e8946ffd9262beaac387aa045260805/671e4/container.jpg 1014w"],
        "sizes": "(max-width: 1014px) 100vw, 1014px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <p>{`Penggunaan Container pada antar muka:`}</p>
    <ul>
      <li parentName="ul">{`Gunakan container untuk mengelompokan konten dengan level berbeda untuk di atur oleh z-index yang menandakan urutan tumpukan dengan elemen lainnya`}</li>
      <li parentName="ul">{`Dapat menggunakan warna dari Design Tokens Color`}</li>
      <li parentName="ul">{`Sudut radius dapat disesuaikan`}</li>
      <li parentName="ul">{`Elevation dapat disesuaikan dengan Elevation Token level 03 sampai 10`}</li>
      <li parentName="ul">{`Dapat digunakan sebagai objek yang di masking`}</li>
      <li parentName="ul">{`Perhatikan bahwa Elevation level 02 dikecualikan untuk digunakan hanya sebagai tombol`}</li>
    </ul>
    <h3>{`Sheets`}</h3>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1014px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "16.319444444444446%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAADABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3qFB/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABPyF//9oADAMBAAIAAwAAABAAD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAAIDAQAAAAAAAAAAAAAAAAERABAhYf/aAAgBAQABPxA4l2AbX//Z')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "container",
        "title": "container",
        "src": "/static/cdb29c84b6f4b67d209b069e63833e8b/671e4/sheet.jpg",
        "srcSet": ["/static/cdb29c84b6f4b67d209b069e63833e8b/69549/sheet.jpg 288w", "/static/cdb29c84b6f4b67d209b069e63833e8b/473e3/sheet.jpg 576w", "/static/cdb29c84b6f4b67d209b069e63833e8b/671e4/sheet.jpg 1014w"],
        "sizes": "(max-width: 1014px) 100vw, 1014px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <p>{`Penggunaan Sheets pada antar muka:`}</p>
    <ul>
      <li parentName="ul">{`Gunakan Drift Sheet sebagai komponen menu flyout dari sisi kiri atau kanan pada ukuran layar`}</li>
      <li parentName="ul">{`Gunakan Sink atau Fly Sheet sebagai komponen menu flyout dari sisi atas atau bawah pada ukuran layar`}</li>
      <li parentName="ul">{`Dapat menggunakan warna dari Design Tokens Color`}</li>
      <li parentName="ul">{`Sudut radius dapat disesuaikan`}</li>
      <li parentName="ul">{`Dapat digunakan sebagai objek yang di masking`}</li>
    </ul>
    <h3>{`Table Cell`}</h3>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1014px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "16.319444444444446%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAADABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB36AH/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABPyF//9oADAMBAAIAAwAAABAAD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABUQAQEAAAAAAAAAAAAAAAAAABAR/9oACAEBAAE/EI//2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "container",
        "title": "container",
        "src": "/static/920c5aa76bd03894092527a800e5c2e8/671e4/cell.jpg",
        "srcSet": ["/static/920c5aa76bd03894092527a800e5c2e8/69549/cell.jpg 288w", "/static/920c5aa76bd03894092527a800e5c2e8/473e3/cell.jpg 576w", "/static/920c5aa76bd03894092527a800e5c2e8/671e4/cell.jpg 1014w"],
        "sizes": "(max-width: 1014px) 100vw, 1014px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <p>{`Penggunaan Table Cell pada antar muka:`}</p>
    <ul>
      <li parentName="ul">{`Gunakan Table Cell sebagai komponen pada kolom table`}</li>
      <li parentName="ul">{`Dapat menggunakan warna dari Design Tokens Color`}</li>
      <li parentName="ul">{`Dapat menggunakan warna border dari Design Token Border`}</li>
      <li parentName="ul">{`Dapat merubah ketebalan Border`}</li>
      <li parentName="ul">{`Dapat digunakan sebagai objek yang di masking`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      